
import {
  defineComponent,
  createVNode, 
  UnwrapRef,
  onMounted,
  reactive,
  computed,
  ref
} from "vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useStore } from "vuex";
import { Modal } from 'ant-design-vue'

interface FormState {
  roles: any;
  title: string;
  content: string;
}

export default defineComponent({
  setup() {
    const form : UnwrapRef<FormState> = reactive({
      id: "",
      roles: [],
      title: "",
      content: "",
    });
    const isLoading = ref<boolean>(false)
    const store = useStore();

    const handleFinish = async (values: FormState) => {
        store.dispatch('Announcement/store', form)
    }

    const getFormOptions = () => {
      store.dispatch('Option/getAllRoles')
    }

    const showMessage = (payload = {}) => {
      Modal.confirm({
        title: 'Confirm Post',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', {style: 'color:red;'}, 'Are you sure you want to submit this announcement? Please double check before clicking the submit button.'),
        onOk () {
          isLoading.value = true
          store.dispatch('Announcement/store', form).then(() => (
            form.roles=[],
            form.title="",
            form.content="",

            isLoading.value = false
          )).catch(() => {
            isLoading.value = false
          })
        },
        class: 'test',
      })
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const setDisable = () => {
      return form.roles.length === 0 || form.title === '' || form.content === ''
    } 

    const roles = computed(() => store.getters['Option/all_roles'])
    const isDisabled = computed(() => setDisable())

    onMounted(getFormOptions)
    return {
      handleFinish,
      filterOption,
      showMessage,
      isLoading,
      form,
      roles,
      isDisabled
    };
  },
});
